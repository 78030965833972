<template>
  <!--begin::Project Listing-->
  <div class="opportunity-template" v-if="getPermission('opportunity:view')">
    <PageHeaderCount
      moduleType="opportunity"
      :dataLoading="dataLoading"
      :statusList="statusList2"
      allkey="all_projects"
      countkey="project_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'opportunity-listing'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto selected-rows-text">
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('opportunity:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in projectMoreAction">
                        <v-list-item
                          link
                          v-on:click="updateMoreAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" class="pt-0 col-md-12 col-lg-6">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList2"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_projects
                          }}</template>
                          <template v-else>{{
                            item.project_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col
                cols="12"
                class="pt-0 col-md-12 col-lg-6 justify-flex-end d-flex margin-auto all-project-btn-right"
              >
                <template v-if="getPermission('opportunity:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('opportunity.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <!--  <v-btn
                  v-if="!isEngineerChannel()"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button btn-tab-hide"
                  color="cyan white--text"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'project',
                        },
                      })
                    )
                  "
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn> -->
                <!--   <v-menu content-class="custom-menu-list" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu> -->
                <v-menu
                  max-height="400"
                  max-width="250"
                  left
                  offset-y
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="
                            (Number(cols.fixed) > 0 ? true : false) ||
                            dataLoading
                          "
                          color="cyan"
                          hide-details
                          class="mt-0 mb-0"
                          v-on:change="updateVisible()"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips v-if="false" module="opportunity"></PageTips>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Opportunity"
          :basicSearchFields="['barcode', 'reference', 'name']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData2="rowData2"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{
                        'checkbox-width-limit': cols.checkbox,
                        'pointer-events-none': lodash.isEmpty(rowData2),
                      }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!lodash.isEmpty(rowData2)">
                  <tr
                    v-for="(data, index) in rowData2"
                    :key="index"
                    link
                    class="project-listing-row"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <v-chip small color="blue darken-4" label outlined>
                            O-000024
                          </v-chip>
                          <!-- <Barcode
                            route="opportunity.detail"
                            :id="data.id"
                            :barcode="data.barcode"
                          ></Barcode> -->
                        </template>
                        <template v-else-if="cols.field == 'project_info'">
                          <div
                            class="project-listing-project"
                            style="width: 300px"
                          >
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Name: </b> {{ data.name }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Reference #: </b>
                              <template v-if="data.reference">
                                {{ data.reference }}
                              </template>
                              <template v-else
                                ><em class="text--secondary">No Reference </em>
                              </template>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Start Date: </b>
                              {{ formatDate(data.started_at) }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>End Date: </b> {{ formatDate(data.deadline) }}
                            </p>

                            <!-- <p class="m-0 custom-nowrap-ellipsis">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    class="custom-status d-inline font-weight-600 custom-grey-border text-uppercase justify-center"
                                    color="cyan"
                                    text-color="white"
                                    label
                                    small
                                  >
                                    {{ data.billing_type_text }}
                                  </v-chip>
                                </template>
                                <span>Billing Type</span>
                              </v-tooltip>
                            </p> -->
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'milestone'">
                          <div
                            class="project-listing-project"
                            v-if="
                              data &&
                              data.milestone &&
                              data.milestone.length > 0
                            "
                          >
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Name: </b>
                              <template
                                v-if="
                                  data &&
                                  data.milestone &&
                                  data.milestone[0] &&
                                  data.milestone[0].name
                                "
                              >
                                {{ data.milestone[0].name }}
                              </template>
                              <template v-else
                                ><em class="text--secondary">No name </em>
                              </template>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Date : </b>
                              <template
                                v-if="
                                  data &&
                                  data.milestone &&
                                  data.milestone[0] &&
                                  data.milestone[0].date
                                "
                              >
                                {{
                                  formatDate(
                                    data &&
                                      data.milestone &&
                                      data.milestone[0] &&
                                      data.milestone[0].date
                                  )
                                }}
                              </template>
                              <template v-else
                                ><em class="text--secondary">No date </em>
                              </template>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Reminder: </b>
                              {{
                                formatDate(
                                  data &&
                                    data.milestone &&
                                    data.milestone[0] &&
                                    data.milestone[0].reminder_date
                                )
                              }}
                            </p>
                            <template
                              v-if="
                                data &&
                                data.milestone &&
                                data.milestone.length > 1
                              "
                            >
                              <v-chip small color="blue white--text" label>
                                <span class="font-size-16 font-weight-500">
                                  +{{
                                    data &&
                                    data.milestone &&
                                    data.milestone.length - 1
                                  }}
                                  More</span
                                >
                              </v-chip>
                            </template>
                          </div>
                          <em v-else class="text-muted">
                            no project milestone</em
                          >
                        </template>
                        <template v-else-if="cols.field == 'opne_visit_count'">
                          <div class="contract-listing-customer">
                            <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                              <v-badge
                                color="blue"
                                :content="data.opne_visit_count"
                                class="mr-9 ml-2"
                              >
                                <!--   Item Two -->
                              </v-badge>
                              <b>Open </b>
                              <!-- <template v-if="data.total_service">{{
                                data.total_service
                              }}</template> -->
                            </p>

                            <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                              <v-badge
                                color="orange"
                                :content="data.overdue_visit_count"
                                class="mr-9 ml-2"
                              >
                              </v-badge>
                              <b>Overdue</b>
                            </p>
                            <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                              <v-badge
                                color="yellow"
                                class="mr-9 ml-2"
                                :content="data.inprogress_visit_count"
                              >
                              </v-badge>
                              <b>In-Progress </b>
                            </p>
                            <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                              <v-badge
                                color="green"
                                class="mr-9 ml-2"
                                :content="data.completed_visit_count"
                              >
                              </v-badge>
                              <b>Completed </b>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'amount'">
                          <div class="project-listing-project">
                            <!-- <p class="m-0 custom-nowrap-ellipsis">
                              <b>Opportunity Value: </b>
                              <template v-if="data && data.project_value">
                                <span class="font-weight-700">{{
                                  formatMoney(data.project_value)
                                }}</span></template
                              >
                            </p> -->
                            <p class="m-0 custom-nowrap-ellipsis">
                              <template v-if="data && data.project_budget"
                                ><span class="font-weight-700">{{
                                  formatMoney(data.project_budget)
                                }}</span></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'customer'">
                          <div
                            class="project-listing-project"
                            v-if="data.customer"
                          >
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              {{ data.customer && data.customer.display_name }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company: </b>
                              <template
                                v-if="
                                  data.customer && data.customer.company_name
                                "
                              >
                                {{
                                  data.customer && data.customer.company_name
                                }}
                              </template>
                              <em v-else class="text--secondary">no company</em>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b> Email : </b>
                              <template
                                v-if="
                                  data.customer && data.customer.company_email
                                "
                              >
                                {{ data.customer.company_email }}</template
                              >
                              <em v-else class="text--secondary">no email</em>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone Number : </b>
                              <template
                                v-if="
                                  data.customer && data.customer.company_number
                                "
                              >
                                {{ data.customer.company_number }}</template
                              >
                              <em v-else class="text--secondary">no phone</em>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <div class="project-listing-status">
                            <CustomStatus
                              v-if="false"
                              small
                              :status="data.status"
                              endpoint="project/status"
                            ></CustomStatus>
                            <v-chip
                              :color="getColor(data.status)"
                              label
                              v-if="data.status"
                              class="ml-2"
                            >
                              <span class="font-size-16 font-weight-500">
                                {{ getStatus(data.status) }}
                              </span>
                            </v-chip>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'dates'">
                          <div class="project-listing-dates">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Started At: </b>
                              {{ formatDate(data.started_at) }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Finished At: </b>
                              <template v-if="data.finished_at">{{
                                formatDate(data.finished_at)
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >Not Finished Yet</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Deadline: </b>
                              <template v-if="data.deadline">{{
                                formatDate(data.deadline)
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Deadline</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'property_info'">
                          <p
                            v-if="data.property_person"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <v-icon small left>mdi-account</v-icon>
                            {{
                              data.property_person &&
                              data.property_person.display_name
                            }}
                          </p>
                          <p
                            v-if="data.property_person"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <v-icon small left>mdi-email</v-icon>
                            <template
                              v-if="
                                data.property_person &&
                                data.property_person.primary_email
                              "
                            >
                              {{ data.property_person.primary_email }}</template
                            >
                            <em v-else class="text--secondary">no email</em>

                            <!--   {{
                              data.property_person &&
                              data.property_person.primary_email
                            }} -->
                          </p>
                          <p
                            v-if="data.property_person"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <v-icon small left>mdi-phone</v-icon>
                            <template
                              v-if="
                                data.property_person &&
                                data.property_person.primary_phone
                              "
                            >
                              {{ data.property_person.primary_phone }}</template
                            >
                            <em v-else class="text--secondary">no phone</em>
                            <!--   {{
                              data.property_person &&
                              data.property_person.primary_phone
                            }} -->
                          </p>
                          <p
                            v-if="data.property"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <v-icon size="16" class="mr-2"
                              >mdi-map-marker</v-icon
                            >
                            <span style="display: inline-flex !important">
                              {{ data?.property?.property_name }}
                              {{ data.property.street_1 }}
                              {{ data.property.street_2 }}
                              <br />
                              {{ data.property.unit_no }}
                              {{ data.property.zip_code }}
                              {{ data.property.country }}</span
                            >
                          </p>
                        </template>
                        <template
                          v-else-if="cols.field == 'billing_address' && false"
                        >
                          <p
                            v-if="data.billing_person"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <v-icon small left>mdi-account</v-icon>
                            {{
                              data.billing_person &&
                              data.billing_person.display_name
                            }}
                          </p>
                          <p
                            v-if="data.billing_person"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <v-icon small left>mdi-email</v-icon>
                            {{
                              data.billing_person &&
                              data.billing_person.primary_email
                            }}
                          </p>
                          <p
                            v-if="data.billing_person"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <v-icon small left>mdi-phone</v-icon>
                            {{
                              data.billing_person &&
                              data.billing_person.primary_phone
                            }}
                          </p>
                          <p
                            v-if="data.billing"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <v-icon size="16" class="mr-1"
                              >mdi-map-marker</v-icon
                            >
                            {{ data.billing && data.billing.property_address }}
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="8" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no opportunity at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Opportunity</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Opportunity')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <template v-if="customerDialog">
          <CustomerDialog
            :customerDialog="customerDialog"
            v-on:closeDialog="customerDialog = false"
            v-on:resetAll="customerDialog = false"
            v-on:selectCustomer="selectCustomer"
          ></CustomerDialog>
        </template>
        <template v-if="customerPropertyDialog">
          <CustomerPropertyDialog
            disabled-auto-select
            :customerPropertyDialog="customerPropertyDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerProperty="selectCustomerProperty"
          ></CustomerPropertyDialog>
        </template>
        <FullDialog
          v-if="notesDialog"
          :dialog="notesDialog"
          content-class="testdata"
        >
          <template v-slot:title
            >Opportunity(
            <!-- <template v-if="visit.trip_count > 0"
              >{{ visit.barcode }}-{{ visit.trip_count }}</template
            >
            <template v-else>{{ visit.barcode }}-01</template> -->
            ) Notes
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ml-2 mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
                  :color="getStatusColor(visit.status)"
                  label
                  text-color="white"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ visit.status_text }}
                </v-chip>
              </template>
              <span>Status</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn
              class="text-white"
              color="red darken-4"
              depressed
              tile
              v-on:click="notesDialog = fa"
            >
              Close
            </v-btn>
          </template>
          <template v-slot:body>
            <ChatNote
              v-if="notesDialog"
              :relatedId="visitId"
              :relatedType="'opportunity'"
              :documentTypes="26"
              create-url="visit-notes"
              get-url="visit-notes"
            ></ChatNote>
          </template>
          <!-- <template v-slot:action>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialog">
					Close
				</v-btn>
			</template> -->
        </FullDialog>
        <div class="floating-btn-wrapper">
          <div class="position-relative">
            <div
              class="call-btn-underlay"
              style="background-color: rgb(43, 149, 105)"
            ></div>
            <v-icon
              style="font-size: 50px !important"
              color="green"
              small
              v-on:click="notesDialog = true"
              >mdi-chat
            </v-icon>
          </div>
        </div>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Project Listing-->
</template>

<script>
import draggable from "vuedraggable";
import FullDialog from "@/view/components/FullDialog";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH } from "@/core/services/store/request.module";
// import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import ChatNote from "@/view/pages/partials/ChatNote";
export default {
  mixins: [CommonMixin, ListingMixin],
  name: "opportunity-list",
  data() {
    return {
      pageTips: false,
      customerDialog: false,
      notesDialog: false,
      visitId: 0,
      customerPropertyDialog: false,
      exportLoading: false,
      pageModule: "opportunity-listing",
      routeAPI: "opportunity",
      routeName: "opportunity",
      routeDetailName: "opportunity.detail",
      status: "all",
      file: "",
      importDialog: false,
      statusList2: [
        {
          id: 79,
          type: "16",
          text: "All Opportunity",
          value: "all",
          description: "all",
          activity_text: "all",
          color: "cyan",
          textcolor: "white",
          headercolor: "primary",
          order: "1",
          status: "0",
          top_visible: true,
          activated: true,
          added_by: "1",
          added_at: "2024-08-30 09:02:57",
          updated_by: "0",
          updated_at: null,
          user_id: "0",
          visible: "1",
          project_status_count: "0",
          all_projects: 2,
        },
        {
          id: 80,
          type: "16",
          text: "Not Started",
          value: "not_started",
          description: "not_started",
          activity_text: "not_started",
          color: "blue darken-1",
          textcolor: "white",
          headercolor: "primary",
          order: "2",
          status: "1",
          top_visible: true,
          activated: true,
          added_by: "1",
          added_at: "2024-08-30 09:02:57",
          updated_by: "0",
          updated_at: null,
          user_id: "0",
          visible: "1",
          project_status_count: "2",
          all_projects: 2,
        },
        {
          id: 81,
          type: "16",
          text: "In Progress",
          value: "in_progress",
          description: "in_progress",
          activity_text: "in_progress",
          color: "purple",
          textcolor: "white",
          headercolor: "primary",
          order: "3",
          status: "2",
          top_visible: true,
          activated: true,
          added_by: "1",
          added_at: "2024-08-30 09:02:57",
          updated_by: "0",
          updated_at: null,
          user_id: "0",
          visible: "1",
          project_status_count: "0",
          all_projects: 2,
        },
        {
          id: 82,
          type: "16",
          text: "On Hold",
          value: "on_hold",
          description: "on_hold",
          activity_text: "on_hold",
          color: "orange",
          textcolor: "white",
          headercolor: "primary",
          order: "4",
          status: "3",
          top_visible: true,
          activated: true,
          added_by: "1",
          added_at: "2024-08-30 09:02:57",
          updated_by: "0",
          updated_at: null,
          user_id: "0",
          visible: "1",
          project_status_count: "0",
          all_projects: 2,
        },
        {
          id: 83,
          type: "16",
          text: "Cancelled",
          value: "cancelled",
          description: "cancelled",
          activity_text: "cancelled",
          color: "red",
          textcolor: "white",
          headercolor: "primary",
          order: "5",
          status: "4",
          top_visible: true,
          activated: true,
          added_by: "1",
          added_at: "2024-08-30 09:02:57",
          updated_by: "0",
          updated_at: null,
          user_id: "0",
          visible: "1",
          project_status_count: "0",
          all_projects: 2,
        },
        {
          id: 84,
          type: "16",
          text: "Finished",
          value: "finished",
          description: "finished",
          activity_text: "finished",
          color: "green",
          textcolor: "white",
          headercolor: "primary",
          order: "6",
          status: "5",
          top_visible: true,
          activated: true,
          added_by: "1",
          added_at: "2024-08-30 09:02:57",
          updated_by: "0",
          updated_at: null,
          user_id: "0",
          visible: "1",
          project_status_count: "0",
          all_projects: 2,
        },
      ],
      customThead: [],
      rowData2: [
        {
          id: 2,
          name: "fss",
          barcode: "PR000002",
          attention: null,
          reference: null,
          soled_job_no: null,
          customer: {
            id: 1,
            barcode: "C-000004",
            display_name: "Business Thrust Techsoft Pvt Ltd.",
            company_name: "Business Thrust Techsoft Pvt Ltd.",
            company_email: null,
            company_number: null,
            profile_logo: null,
          },
          lead: "0",
          proposal: "0",
          quotation: "0",
          property: {
            id: 1,
            barcode: "P-000008",
            property_name: null,
            customer: 1,
            supplier: "0",
            contact_person: 1,
            is_same: "1",
            is_tenant: 0,
            unit_no: "#01-583",
            street_1: "ATM, Bendemeer Road Branch (UOB)",
            street_2: "25 Bendemeer Road",
            zip_code: "330025",
            country: "0",
            zone_territory: null,
            state: "0",
            city: "0",
            latitude: null,
            longitude: null,
            remarks: null,
            entity: "Business Thrust Pte Ltd.",
            entity_id: "1",
            type: 1,
            activated: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            property_address:
              "ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road #01-583 330025",
          },
          billing: null,
          project_value: "1456",
          project_budget: "545",
          property_contact_person: "1",
          billing_contact_person: "1",
          cost: "0.00",
          rate_per_hour: "0.00",
          estimated_hour: "0.00",
          billing_type: "1",
          started_at: "2024-08-24",
          deadline: "2024-09-24",
          finished_at: null,
          progress: "0",
          status: 1,
          added_by: {
            id: 1,
            display_name: "Admin",
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "Mr. Super Admin",
          },
          added_at: "2024-08-24 11:48:19",
          updated_by: {
            id: 1,
            display_name: "Admin",
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "Mr. Super Admin",
          },
          updated_at: "2024-08-24 11:48:19",
          opne_visit_count: "0",
          overdue_visit_count: "0",
          completed_visit_count: "0",
          inprogress_visit_count: "0",
          created_at: "1 week ago",
          modified_at: "1 week ago",
          billing_type_text: "Fixed Rate",
          status_text: "Not Started",
          property_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          billing_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          milestone: [],
        },
        {
          id: 1,
          name: "Project Nam",
          barcode: "PR000001",
          attention: null,
          reference: null,
          soled_job_no: null,
          customer: {
            id: 1,
            barcode: "C-000004",
            display_name: "Business Thrust Techsoft Pvt Ltd.",
            company_name: "Business Thrust Techsoft Pvt Ltd.",
            company_email: null,
            company_number: null,
            profile_logo: null,
          },
          lead: "0",
          proposal: "0",
          quotation: "0",
          property: {
            id: 1,
            barcode: "P-000008",
            property_name: null,
            customer: 1,
            supplier: "0",
            contact_person: 1,
            is_same: "1",
            is_tenant: 0,
            unit_no: "#01-583",
            street_1: "ATM, Bendemeer Road Branch (UOB)",
            street_2: "25 Bendemeer Road",
            zip_code: "330025",
            country: "0",
            zone_territory: null,
            state: "0",
            city: "0",
            latitude: null,
            longitude: null,
            remarks: null,
            entity: "Business Thrust Pte Ltd.",
            entity_id: "1",
            type: 1,
            activated: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            property_address:
              "ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road #01-583 330025",
          },
          billing: null,
          project_value: "100000",
          project_budget: "90",
          property_contact_person: "1",
          billing_contact_person: "1",
          cost: "0.00",
          rate_per_hour: "0.00",
          estimated_hour: "0.00",
          billing_type: "1",
          started_at: "2024-08-24",
          deadline: "2024-09-24",
          finished_at: null,
          progress: "0",
          status: 1,
          added_by: {
            id: 1,
            display_name: "Admin",
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "Mr. Super Admin",
          },
          added_at: "2024-08-24 11:44:58",
          updated_by: {
            id: 1,
            display_name: "Admin",
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "Mr. Super Admin",
          },
          updated_at: "2024-08-24 11:44:58",
          opne_visit_count: "0",
          overdue_visit_count: "0",
          completed_visit_count: "0",
          inprogress_visit_count: "0",
          created_at: "1 week ago",
          modified_at: "1 week ago",
          billing_type_text: "Fixed Rate",
          status_text: "Not Started",
          property_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          billing_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          milestone: [],
        },
        {
          id: 3,
          name: "Project Name",
          barcode: "PR000001",
          attention: null,
          reference: null,
          soled_job_no: null,
          customer: {
            id: 1,
            barcode: "C-000004",
            display_name: "Business Thrust Techsoft Pvt Ltd.",
            company_name: "Business Thrust Techsoft Pvt Ltd.",
            company_email: null,
            company_number: null,
            profile_logo: null,
          },
          lead: "0",
          proposal: "0",
          quotation: "0",
          property: {
            id: 1,
            barcode: "P-000008",
            property_name: null,
            customer: 1,
            supplier: "0",
            contact_person: 1,
            is_same: "1",
            is_tenant: 0,
            unit_no: "#01-583",
            street_1: "ATM, Bendemeer Road Branch (UOB)",
            street_2: "25 Bendemeer Road",
            zip_code: "330025",
            country: "0",
            zone_territory: null,
            state: "0",
            city: "0",
            latitude: null,
            longitude: null,
            remarks: null,
            entity: "Business Thrust Pte Ltd.",
            entity_id: "1",
            type: 1,
            activated: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            property_address:
              "ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road #01-583 330025",
          },
          billing: null,
          project_value: "100000",
          project_budget: "90",
          property_contact_person: "1",
          billing_contact_person: "1",
          cost: "0.00",
          rate_per_hour: "0.00",
          estimated_hour: "0.00",
          billing_type: "1",
          started_at: "2024-08-24",
          deadline: "2024-09-24",
          finished_at: null,
          progress: "0",
          status: 1,
          added_by: {
            id: 1,
            display_name: "Admin",
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "Mr. Super Admin",
          },
          added_at: "2024-08-24 11:44:58",
          updated_by: {
            id: 1,
            display_name: "Admin",
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "Mr. Super Admin",
          },
          updated_at: "2024-08-24 11:44:58",
          opne_visit_count: "0",
          overdue_visit_count: "0",
          completed_visit_count: "0",
          inprogress_visit_count: "0",
          created_at: "1 week ago",
          modified_at: "1 week ago",
          billing_type_text: "Fixed Rate",
          status_text: "Not Started",
          property_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          billing_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          milestone: [],
        },
      ],
      moreActions: [
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      projectMoreAction: [
        {
          title: "Mark as Finished",
          action: "mark_as_finish",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Cancelled",
          action: "mark_as_cancel",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Hold",
          action: "mark_as_hold",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as In-Progress",
          action: "mark_as_inprogress",
          icon: "mdi-check-all",
        },
      ],
    };
  },
  components: {
    FullDialog,
    ChatNote,
    draggable,
    CustomerDialog,
    CustomerPropertyDialog,
    PageTips,
    PageHeaderCount,
    // Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    CustomStatus,
  },

  methods: {
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "mark_as_finish":
          _this.updateProjectStatus(5);
          break;
        case "mark_as_cancel":
          _this.updateProjectStatus(4);
          break;
        case "mark_as_hold":
          _this.updateProjectStatus(3);
          break;
        case "mark_as_inprogress":
          _this.updateProjectStatus(2);
          break;
      }
    },
    getStatus(status) {
      if (status == 1) {
        return "Not Started";
      } else if (status == 2) {
        return "In Progress";
      } else if (status == 3) {
        return "On Hold";
      } else if (status == 4) {
        return "Cancelled";
      } else if (status == 5) {
        return "Finished";
      }
    },
    getColor(status) {
      if (status == 1) {
        return "blue white--text";
      } else if (status == 2) {
        return "purple white--text";
      } else if (status == 3) {
        return "orange white--text";
      } else if (status == 4) {
        return "red white--text";
      } else if (status == 5) {
        return "green white--text";
      }
    },
    updateProjectStatus(status) {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "project/status",
          data: { project: _this.selectedRows, status },
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },

    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkProject();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },

    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkProject();
    },

    checkProject() {
      const _this = this;
      _this.$router.push(_this.getDefaultRoute("opportunity.create", {}));
    },

    resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },

    closeDialog() {
      this.customerDialog = false;
      this.customerPropertyDialog = false;
    },
    /* selectCustomer(customer) {
      if (customer > 0) {
        this.$router.push(
          this.getDefaultRoute("project.create", {
            query: { customer },
          })
        );
      }
    }, */
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkProjectExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("project");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Opportunity",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
